<template>
  <div class="flex flex-col gap-4 lg:gap-6">
    <div class="flex justify-between" v-if="showSort">
      <SelectSingleList
        v-if="showSort"
        v-model="currentSorting"
        :options="orderStrings"
        label="Sort by"
      />
      <div v-else />

      <!--<NamiSelect @select-event="(val) => changeViewMode(parseInt(val.value.toString()))" v-model="viewMode" v-bind="{
        select : {
          id: 'switchviewmode',
          label: `View mode (${viewMode.text})`,
          name: 'Change view mode',
          options: viewModes
        }
      }" />-->
    </div>

    <TitlesDisplayCover v-if="viewMode.value === 0" :titles="titles" />
    <TitlesDisplaySimple v-else-if="viewMode.value === 1" :titles="titles" />
    <TitlesDisplayExpanded v-else-if="viewMode.value === 2" :titles="titles" />
    <TitlesDisplayDetailed v-else-if="viewMode.value === 3" :titles="titles" />

    <!-- pagination-->
    <NamiPagination
      v-if="showPagination"
      v-model="page"
      :total-items="meta.total"
      :total-pages="Math.ceil(meta.total / meta.limit)"
    />
  </div>
</template>

<script setup lang="ts">
import type { ParsedTitleOutput } from "~/utils/display/title";

interface Props {
  titles?: Pick<
    ParsedTitleOutput,
    "title" | "description" | "coverUrl" | "href"
  >[];
  meta: {
    limit: number;
    offset: number;
    total: number;
  };
  showSort?: boolean;
  showPagination?: boolean;
}

const props = defineProps<Props>();

const viewModes = getViewModes();
const orderStrings = getOrderStrings();

const router = useRouter();

const filterIsShown = ref(false);
const viewMode = ref(viewModes[0]);
const currentSorting = ref(orderStrings[0].value);

const searchTerm = ref("");

const page = ref(Math.floor(props.meta.offset / props.meta.limit) + 1);
watch(
  () => page.value,
  (newPage) => router.push({ query: { page: newPage } }),
);

function changeSorting(sort: string) {
  const [type, val] = sort.split(".");
}

function changeViewMode(mode: number) {
  viewMode.value = viewModes[mode];
}

function changeSearchTerm(searchTerm: string) {}
</script>
